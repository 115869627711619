import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import * as images from "../Constant/images";
import {
  chatbotextention,
  chatbotline,
  chatbotaudio,
  chatbotdocument,
  chatbotemoji,
  chatbotsender,
} from "../Constant/svgs";
import { X } from "lucide-react";
import "bootstrap/dist/css/bootstrap.min.css";

export const ChatBot = (props) => {
  const textRef = useRef();
  const chatBoxRef = useRef();
  const [chatOpen, setChatOpen] = useState(true);
  const [messages, setMessages] = useState([
    {
      sender: "bot",
      text: "Hi, I am Eva, I can assist you regarding your queries about Swati Tech!",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [typingMessage, setTypingMessage] = useState("");

  const toggleChat = () => {
    setChatOpen(!chatOpen);
    if (!chatOpen) {
      scrollToBottom();
    }
  };

  const handleSend = async () => {
    const userMessage = textRef.current.value;
    if (!userMessage.trim()) return;

    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "user", text: userMessage },
    ]);
    textRef.current.value = "";
    setLoading(true);

    try {
      const response = await axios.post(
        // "http://92.204.170.99:5001/chat_query_new",
        "https://chat-bot.swatitech.com/chat_query_new",
        {
          msg: userMessage,
          model_engine: "gpt-3.5-turbo",
          temperature: 0,
          system_prompt: "You are a helpful AI.",
          user_id: 124,
          session_id: 2000,
          choice: "chat",
        }
      );
      const botMessage = response.data.document.answer;
      typeWriterEffect(botMessage);
    } catch (error) {
      console.error("Error sending message to API:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  const typeWriterEffect = (message) => {
    let i = -1;
    setTypingMessage("");
    const interval = setInterval(() => {
      if (i < message.length) {
        setTypingMessage((prev) => prev + message.charAt(i));
        i++;
      } else {
        clearInterval(interval);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: message },
        ]);
        setTypingMessage("");
      }
    }, 50);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (chatOpen) {
      scrollToBottom();
    }
  }, [chatOpen]);

  return (
    <>
      <div id="chatCon" className="d-flex flex-column">
        <div
          className={`chat-box ${chatOpen ? "d-flex" : "d-none"} flex-column`}
        >
          <div className="chatbot_header d-flex justify-content-between align-items-center p-2 p-lg-3">
            <div>
              <img src={images.CHATBOT1} alt="CHATBOT" width={70} />
            </div>
            <div>
              <X color="white" size={20} onClick={toggleChat} />
            </div>
          </div>
          <div className="flex-grow-1 overflow-auto" ref={chatBoxRef}>
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`d-flex mt-3 ${
                  msg.sender === "user"
                    ? "me-3 justify-content-end"
                    : "ms-3 gap-3"
                } align-items-end`}
              >
                {msg.sender === "bot" && (
                  <img src={images.CHATBOT2} alt="CHATBOT2" width={30} />
                )}
                <div
                  className={`chatbot_${
                    msg.sender === "user" ? "recieve" : "send"
                  } w-75 p-0 m-0`}
                >
                  <p
                    className={`chatbt_${
                      msg.sender === "user" ? "recieve" : "send"
                    }_p pt-2 pb-2 ps-3 pe-3 m-0`}
                  >
                    {msg.text}
                  </p>
                </div>
              </div>
            ))}
            {typingMessage && (
              <div className="d-flex mt-3 ms-3 gap-3 align-items-end">
                <div>
                  <img src={images.CHATBOT2} alt="CHATBOT2" width={30} />
                </div>
                <div className="chatbot_send w-75 p-0 m-0">
                  <p className="chatbt_send_p pt-2 pb-2 ps-3 pe-3 m-0">
                    {typingMessage}
                  </p>
                </div>
              </div>
            )}
            {loading && (
              <div className="d-flex mt-3 ms-3 gap-3 align-items-end">
                <div>
                  <img src={images.CHATBOT2} alt="CHATBOT2" width={30} />
                </div>
                <div className="chatbot_loading p-1 ps-2 pe-2 m-0">
                  <div className="loader"></div>
                </div>
              </div>
            )}
          </div>
          <div className="chatbot_footer p-3">
            <div>
              <input
                className="chatbox_input"
                name="Chatbot"
                placeholder="Type your message..."
                ref={textRef}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div className="d-flex justify-content-between mt-3">
              <div className="d-flex gap-3 cursor-pointer">
                {chatbotextention}
                {chatbotline}
                {chatbotaudio}
                {chatbotdocument}
                {chatbotemoji}
              </div>
              <div onClick={handleSend} className="cursor-pointer">
                {chatbotsender}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pop">
        <p>
          <img
            onClick={toggleChat}
            src={images.CHATBOT}
            alt="CHATBOT"
            className="CHATBOTIMG"
            width={60}
          />
        </p>
      </div>
    </>
  );
};

export default ChatBot;
