import ChatBot from "./View/ChatBot";
import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import LoadingScreen from "./Components/LoadingScreen";
import Footer from "./Components/Footer";

const AppRouter = () => {
  const Home = lazy(() => import("./View/Home"));
  const SYMPage = lazy(() => import("./View/SYMPage"));
  const ACEPage = lazy(() => import("./View/ACEPage"));
  const AboutPage = lazy(() => import("./View/AboutPage"));
  const LegalPage = lazy(() => import("./View/LegalPage"));
  const TermsPage = lazy(() => import("./View/TermsPage"));
  const ContactPage = lazy(() => import("./View/Contact"));
  const DidConnect = lazy(() => import("./View/DidConnect"));
  const DeletePage = lazy(() => import("./View/DeletePage"));
  const PrivacyPage = lazy(() => import("./View/PrivacyPage"));
  const CareersPage = lazy(() => import("./View/CareersPage"));
  const EstimatePage = lazy(() => import("./View/EstimatePage"));
  const CareersDetail = lazy(() => import("./View/CareersDetail"));
  const AttendifyPage = lazy(() => import("./View/AttendifyPage"));
  const MetaversePage = lazy(() => import("./View/MetaversePage"));
  const SecureEyePage = lazy(() => import("./View/SecureEyePage"));
  const WebAppDevelopement = lazy(() => import("./View/WebAppDevelopement"));
  const UiUxDevelopemntPage = lazy(() => import("./View/UiUxDevelopemntPage"));
  const StaffAugmentationPage = lazy(() =>
    import("./View/StaffAugmentationPage")
  );

  return (
    <>
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="/sym"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <SYMPage />
              </Suspense>
            }
          />
          <Route
            path="/ace"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <ACEPage />
              </Suspense>
            }
          />
          <Route
            path="/about"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <AboutPage />
              </Suspense>
            }
          />
          <Route
            path="/legal"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <LegalPage />
              </Suspense>
            }
          />
          <Route
            path="/privacy"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <PrivacyPage />
              </Suspense>
            }
          />
          <Route
            path="/careers"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <CareersPage />
              </Suspense>
            }
          />
          <Route
            path="/contact"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <ContactPage />
              </Suspense>
            }
          />
          <Route
            path="/deleteData"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <DeletePage />
              </Suspense>
            }
          />
          <Route
            path="/secureye"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <SecureEyePage />
              </Suspense>
            }
          />
          <Route
            path="/d-idconnect"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <DidConnect />
              </Suspense>
            }
          />
          <Route
            path="/metaverse"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <MetaversePage />{" "}
              </Suspense>
            }
          />
          <Route
            path="/attendify"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <AttendifyPage />
              </Suspense>
            }
          />
          <Route
            path="/termsCondition"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <TermsPage />
              </Suspense>
            }
          />
          <Route
            path="/estimatepage"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <EstimatePage />
              </Suspense>
            }
          />
          <Route
            path="/careersDetail/:id"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <CareersDetail />
              </Suspense>
            }
          />
          <Route
            path="/ui&uxdevelopment"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <UiUxDevelopemntPage />
              </Suspense>
            }
          />
          <Route
            path="/web&appdevelopement"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <WebAppDevelopement />
              </Suspense>
            }
          />
          <Route
            path="/staff-augmentaion"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <StaffAugmentationPage />
              </Suspense>
            }
          />
        </Routes>
          <Footer/>
        <ChatBot messages={[]} getMessage={(msg) => console.log(msg)} />
      </div>
    </>
  );
};

export default AppRouter;
