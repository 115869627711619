import { useState, useContext, createContext, useEffect } from "react";

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [jobs, setJobs] = useState([]);
  const [isScroll, setIsScroll] = useState(false);
  const [isProductOpen, setIsProductOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://career-api.swatitech.com/api/jobs/"
        );
        const data = await response.json();
        setJobs(data);
      } catch (error) {
        console.error("Error fetching job data:", error);
      }
    };
    fetchData();
  }, []);
  // console.log(jobId);
  // const getDataById = (jobId) => {
  //   const responseData = jobs?.find(item => item.id === jobId);
  //   return responseData;
  // };

  const value = {
    jobs,
    toggleSidebar,
    setToggleSidebar,
    isProductOpen,
    setIsProductOpen,
    isHovering,
    isScroll,
    setIsScroll,
    setIsHovering,
    isServicesOpen,
    setIsServicesOpen
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const Store = () => useContext(AppContext);
