import React, { useEffect, useState } from "react";
import { loadingIcon } from "../Constant/images";

const LoadingScreen = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setProgress(prevProgress => (prevProgress + 1) % 101);
      }, 30);
      return () => clearInterval(interval); 
    }, []);
  return (
    <div className="loadingMain">
        <div>
            <img src={loadingIcon} alt="logo" width={170} />
        </div>
        <div className="progress-container">
            <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
    </div>
  );
};

export default LoadingScreen;
