import React, { useEffect, useRef } from "react";
import Logo from "../assets/images/logo.png";
import {
  faceIconFooter,
  linkIconFooter,
  instIcon,
  twitIconFooter,
  locateIconFooter,
  bulletIconFooter,
} from "../Constant/svgs";
import { Mail, Phone } from "lucide-react";
import { Link } from "react-router-dom";
import { Store } from "../ContextAPI/Context";
const Footer = () => {
  const { setIsProductOpen,setIsServicesOpen } = Store();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };
  const openEmail = () => {
    window.location.href = "mailto:contact@swatitech.com";
  };
  const HandleProductNavbar=()=>{
    scrollToTop();
    setIsProductOpen(true);
    
    }
  const HandleServicesNavbar=()=>{
    scrollToTop();
    setIsServicesOpen(true);
    
    }

    

  

  return (
    <>
      <div className="footer_main_div">
        {/* <div className="container-fluid"> */}
        <div className="row g-0 align-items-center justify-content-center">
          <div className="col-lg-8 col-md-10 col-sm-11 col-11 m-auto">
            <div className="row pt-5 pb-0 pb-lg-5">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <Link
                  className="text-decoration-none"
                  to="/"
                  onClick={scrollToTop}
                >
                  <img src={Logo} alt="logo" width={80} />
                </Link>
                <p className="footer_para mt-4">
                  Swati Technologies is a premier technology services company
                  that excels in Software Development, Blockchain, Artificial
                  Intelligence, Machine Learning, and Metaverse.
                </p>
                <div className="d-flex align-items-center gap-3 mt-4 pt-2">
                  <div>
                    <Link
                      to="https://www.facebook.com/techswati/?_rdr"
                      className="circle-div"
                    >
                      {faceIconFooter}
                    </Link>
                  </div>
                  <div>
                    <Link to="" className="circle-div">
                      {twitIconFooter}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="https://www.instagram.com/swatitechnologies/"
                      className="circle-div"
                    >
                      {instIcon}
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="https://pk.linkedin.com/company/swatitechnologies"
                      className="circle-div"
                    >
                      {linkIconFooter}
                    </Link>
                  </div>
                </div>
                <p
                  onClick={openEmail}
                  className="footer_para_mail d-flex gap-2 align-items-center mt-4 cursor_pointer"
                >
                  <Mail size={16} color="white" /> contact@swatitech.com
                </p>
              </div>
              <div className="col-lg-2 col-md-2 col-6 d-none d-md-block">
                <h5 className="footer_h5 mt-3">Quick&nbsp; Links</h5>
                <Link
                  className="text-decoration-none"
                  to="/"
                  onClick={scrollToTop}
                >
                  <p className="footer_para_1 mt-4 pt-2">Home</p>
                </Link>
                <Link
                  className="text-decoration-none"
                  to="/about"
                  onClick={scrollToTop}
                >
                  <p className="footer_para_1">About</p>
                </Link>
                <p className="footer_para_1 cursor_pointer nav-link d-md-flex d-none align-items-center gap-1"
                  onClick={HandleProductNavbar}>Products</p>
                <p className="footer_para_1 cursor_pointer" onClick={HandleServicesNavbar}>Services</p>
                <Link className="text-decoration-none" to="https://ticket.swatitech.com/">
                  <p className="footer_para_1 cursor_pointer">Support</p>
                </Link>
              </div>
              <div className="col-lg-6 col-md-6 col-6 d-none d-md-block">
                <h5 className="footer_h5 mt-3">Locations</h5>
                <div className="row mt-2 mt-lg-4 mt-md-4 pt-2">
                  <div className="col-6">
                    <p className="heading_decoration_none">United States</p>
                    <div className="d-flex gap-2 align-content-center cursor_pointer">
                      <div className="d-flex align-items-start pt-1">
                        {locateIconFooter}
                      </div>
                      <p className="footer_para_2 w-75">
                        <Link
                          className="footer_para_2 text-decoration-none"
                          to="https://maps.app.goo.gl/B3ESLSuHWy8pWb8n9"
                          target="_blank"
                        >
                          1 Tara Boulevard, Suite 101, Nashua, NH-03062
                        </Link>
                      </p>
                    </div>
                    <p className="footer_para_2 d-flex align-items-center gap-2 cursor_pointer">
                      <a
                        href="tel:+16034848016"
                        className="footer_para_2 text-decoration-none d-flex gap-2"
                      >
                        <Phone size={17} color="white" />
                        {/* +1 (603) 484-8016 */}
                        +1 (603) 765 2094
                      </a>
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="heading_decoration_none">Pakistan</p>
                    <div className="d-flex gap-2 align-content-center cursor_pointer">
                      <div className="d-flex align-items-start pt-1">
                        {locateIconFooter}
                      </div>
                      <p className="footer_para_2 w-75">
                        <Link
                          className="footer_para_2 text-decoration-none"
                          to="https://maps.app.goo.gl/gYRuHMoD3EN7wypF9"
                          target="_blank"
                        >
                          14-CCA, Sector A, Phase 5, DHA, Lahore
                          <span className="visiblty">kkkkk</span>
                        </Link>
                      </p>
                    </div>
                    <p className="footer_para_2 d-flex align-items-center gap-2 cursor_pointer">
                      <a
                        href="tel:+924235690000"
                        className="footer_para_2 text-decoration-none d-flex gap-2"
                      >
                        <Phone size={17} color="white" />
                        +92 42 35 69 0000
                      </a>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="heading_decoration_none">
                      United Arab Emirates
                    </p>
                    <div className="d-flex gap-2 align-content-center cursor_pointer">
                      <div className="d-flex align-items-start pt-1">
                        {locateIconFooter}
                      </div>
                      <p className="footer_para_2 w-75 footer_para_space">
                        <Link
                          className="footer_para_2 text-decoration-none"
                          to="https://maps.app.goo.gl/UFFjw974iW4ypksY6"
                          target="_blank"
                        >
                          304 Detroit House, Motorcity,<br className="d-xl-none d-md-block"/> Dubai
                        </Link>
                      </p>
                    </div>
                    <p className="footer_para_2 d-flex align-items-center gap-2 cursor_pointer">
                      <a
                        href="tel:+97148854062"
                        className="footer_para_2 text-decoration-none d-flex gap-2"
                      >
                        <Phone size={17} color="white" />
                        +971 4 885 4062
                      </a>
                    </p>
                  </div>
                  {/* <div className="col-6">
                    <p className="heading_decoration_none">Pakistan</p>
                    <div className="d-flex gap-2 align-content-center cursor_pointer">
                      <div className="d-flex align-items-start pt-1">
                        {locateIconFooter}
                      </div>
                      <p className="footer_para_2 footer_para_space">
                        <Link
                          className="footer_para_2 text-decoration-none"
                          to="https://maps.app.goo.gl/kcrUJm31qgedjWmm7"
                          target="_blank"
                        >
                          716, 7th Floor, Caesar’s Tower, Shahrah-e-Faisal
                          Karachi
                        </Link>
                      </p>
                    </div>
                    <p className="footer_para_2 d-flex align-items-center gap-2 cursor_pointer">
                      <a
                        href="tel:+923211115141"
                        className="footer_para_2 text-decoration-none d-flex gap-2"
                      >
                        <Phone size={17} color="white" />
                        +92 321 1115141
                      </a>
                    </p>
                  </div> */}
                </div>
              </div>
              {/* <div className="col-6 smDisplayNone"></div> */}
            </div>
          </div>
        </div>
        {/* </div> */}
        <hr className="footer_hr" />
        <div className="pb-3 pt-3">
          <div className="row g-0 align-items-center justify-content-center">
            <div className="col-lg-8 col-md-10 col-sm-11 col-11 m-auto">
              <div className="row g-0">
                <div className="col-lg-3 col-md-3 col-sm-12 text-center text-md-start text-lg-start order-2 order-md-1">
                  <div className="d-flex gap-2 align-items-center">
                    <div>
                      <Link
                        className="text-decoration-none"
                        to="/legal"
                        onClick={scrollToTop}
                      >
                        <p className="footer_para_2 m-0 p-0">Legal</p>
                      </Link>
                    </div>
                    <div>{bulletIconFooter}</div>
                    <div>
                      <Link
                        className="text-decoration-none"
                        to="/termsCondition"
                        onClick={scrollToTop}
                      >
                        <p className="footer_para_2 p-0 m-0"> Terms of Use</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-start mt-2 my-sm-auto text-lg-center text-md-center order-2 order-md-1">
                  <p className="footer_para_4 mt-3 mt-lg-0 mt-md-0 m-md-auto p-0">
                    Swati Technologies LLP © 2024. All rights reserved.
                  </p>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 text-center text-md-start text-lg-start order-1 order-md-2">
                  <div className="d-flex justify-content-center gap-2 align-items-center smAlignmentStart">
                    <div>
                      <Link
                        className="text-decoration-none"
                        to="/privacy"
                        onClick={scrollToTop}
                      >
                        <p className="footer_para_2 text-nowrap m-0 p-0 ">Privacy Policy</p>
                      </Link>
                    </div>
                    <div>{bulletIconFooter}</div>
                    <div>
                      <Link
                        className="text-decoration-none"
                        to="/deleteData"
                        onClick={scrollToTop}
                      >
                        <p className="footer_para_2 text-nowrap p-0 m-0"> Delete My Data</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
